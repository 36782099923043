import {Component, Input, OnChanges} from '@angular/core';
import {EChartsOption} from "echarts";
import {UtilService} from "../../../services/util.service";

@Component({
  selector: 'candle-stick-chart',
  templateUrl: './candle-stick-chart.component.html',
  styleUrls: ['./candle-stick-chart.component.scss']
})
export class CandleStickChartComponent implements OnChanges {
  chartOption: EChartsOption = {};
  @Input() data: any;
  @Input() legendTitle: any = '';
  @Input() legendSubTitle: any = '';
  @Input() legend: any;
  @Input() zoom: any;
  @Input() format: any;
  @Input() height: any = 500;
  @Input() width: any = 1000;

  ngOnChanges(): void {
    let type = "date";
    this.chartOption =
      {
        xAxis: {
          axisLabel: {
            formatter: function _(val: any) {
              let d = new Date(val - 86400 * 1000);
              return UtilService.formatTo(d, type);
            }
          },
          axisLine: {
            show: true,
            onZero: true,
            // onZeroAxisIndex: this.data[0][0] //не понял зачем это нужно
          },
          axisTick: {
            show: true
          },
          nameLocation: "start",
          //name: title,
          type: 'time',

          splitLine: {
            show: true
          }
        },
        yAxis: {
          axisLabel: {
            formatter: function _(val: any) {
              return val.toLocaleString();
            }
          },
          position: "right",
          //name: 'Позиции',
          nameGap: 20,
          type: 'value',
          nameLocation: 'start'
        },
        series: [
          {
            type: 'candlestick',
            data: this.data
          }
        ]
      };
    if (this.legendTitle) {
      this.chartOption.title = {
        text: this.legendTitle,
        textStyle: {
          fontSize: 20,
          color: '#000000',
          opacity: 0.2
        },
        z: 0,
        left: '50%',
        bottom: '50%',
        textAlign: 'center',
        textVerticalAlign: 'middle',
        subtext: this.legendSubTitle,
        subtextStyle: {
          fontSize: 20,
          color: '#000000',
          opacity: 0.2
        }
      }
      //this.chartOption.legend = {}
    }
    if (this.legend) {
      this.chartOption.legend = {}
    }
  }
}
