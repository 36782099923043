import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MaxGetService} from "../../core/ngrx/services/max/max-get.service";
import {isEmpty, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {TotalGetService} from "../../core/ngrx/services/total/total-get.service";
import {TickerGetService} from "../../core/ngrx/services/ticker/ticker-get.service";
import {Ticker} from "../../core/models/ticker";
import {FormControl} from "@angular/forms";
import {Total} from "../../core/models/total";
import {CodeParam} from "../../core/models/code-param";
import {MaxDtService} from "../../core/websocket/max-dt.service";
import {CodeDtParam} from "../../core/models/code-dt-param";
import {Title} from "@angular/platform-browser";
import {AuthService} from "../../core/services/auth.service";
import {UtilService} from "../../core/services/util.service";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [TickerGetService, TotalGetService, MaxGetService]
})
export class HomeComponent implements OnInit, OnDestroy {
  private _sub: Subscription | undefined;
  private _sub1: Subscription | undefined;
  private _sub2: Subscription | undefined;
  total: Total | undefined;
  tickerList: Ticker[] = [];
  tlist: Total[][] = [];
  private totals: Total[] = [];
  is: boolean = false;
  tradeDateControl: FormControl = new FormControl<Date>(new Date());
  private lastUpdateDt: Date = new Date();

  constructor(
    private _router: Router,
    private _store: Store<AppState>,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    private _maxGetService: MaxGetService,
    private _tickerGetService: TickerGetService,
    private _totalGetService: TotalGetService,
    private _maxDtService: MaxDtService,
    private _isAuthService: AuthService,
    private _metaTags: Meta
  ) {
    this._isAuthService.item$.subscribe(u => {
      this.is = this._isAuthService.isAuth;
    })
  }

  ngOnInit(): void {
    this._router.navigate(['daily'])

    // подписываемся на изменение списка тикеров
    this._sub = this._store.select('tickers').subscribe(x => {

      this.tickerList = Object.assign([], x);

      if (this.tickerList[0] != null) {
        // console.log("maxDtService for ", this.tickerList[0].code)
        // this._maxGetService.execute(new CodeParam(this.tickerList[0].code));
        this._maxDtService.run(new CodeParam(this.tickerList[0].code));
      }
    });
    if (this.tickerList.length == 0) {
      this._tickerGetService.execute(Object)
    }


    // пописываемся на изменение данных total
    this._sub2 = this._store.select('totals').subscribe(totals => {
      this.totals = Object.assign([], totals);

      let filteredTotals: Total[] = [];
      for (let ticker of this.tickerList) {
        if (ticker.show_on_main) {
          const totalList = this.totals.filter(t => t.tickerCode == ticker.code).sort((a: Total, b: Total) => {
            if (a.dt > b.dt) return -1;
            if (a.dt < b.dt) return 1;
            return 0;
          });
          if (totalList.length > 0)
            filteredTotals.push(totalList[0])
          this._titleService.setTitle(`Открытые позиции ${(this.is) ? 'онлайн' : ''} - ${filteredTotals.map(t => t.tickerCode).join(', ')} < Betatrader.ru`);
        }
      }
      this._metaTags.addTags([
        {property: 'og:title', content: 'Онлайн открытые позиции по фьючерсам Мосбиржи на Betatrader.ru'},
        {property: 'og:description', content: 'Фьючерсы на индекс RTS (RI), курс доллар США - российский рубль (Si), сырую нефть сорта Brent (BR) и другие активы. Всего более 40 фьючерсов.'},
        {property: 'og:url', content: 'https://betatrader.ru'},
        {property: 'og:image', content: 'https://betatrader.ru/assets/img/OG_image.png'}
      ])
      //разбить массив тоталов по 2
      const perChunk = 2 // items per chunk
      this.tlist = filteredTotals.reduce((all, one, i) => {
          const ch = Math.floor(i / perChunk);
          // @ts-ignore
          all[ch] = [].concat((all[ch] || []), one);
          return all
        },
        [])

    });

    // пописываемся на изменение даты
    this._sub1 = this._store.select('dt').subscribe(dt => {
      if (dt) {
        let now = new Date();
        for (let ticker of this.tickerList) {
          if (ticker.show_on_main) {
            const totalList = this.totals.filter(t => t.tickerCode == ticker.code).sort((a,b)=>{
              if (a.dt < b.dt) return 1;
              if (a.dt > b.dt) return -1;
              return 0;
            });
            if (totalList.length>0)
              this.lastUpdateDt = UtilService.formatFromSring(totalList[0].dt.toString())
            if (totalList.length === 0 || this.lastUpdateDt==null || (this.lastUpdateDt?.getTime() + 300 * 1000) <= dt.getTime()) {
              // console.log("update", now.toLocaleString())
              this._totalGetService.execute(new CodeDtParam(ticker.code, dt));
            }
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
    this._sub1?.unsubscribe();
    this._sub2?.unsubscribe();
    this._maxDtService.stop('unknown')
  }

  selectedTickerName(ticker: string) {
    if (this.tickerList != undefined) {
      for (let x of this.tickerList) {
        if (x.code == ticker) {
          return x.name
        }
      }
    }
    return ""
  }

}
