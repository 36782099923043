<!--<div style="display: flex; justify-content: space-around; padding-top: 10px">-->
<!--    <img src="./assets/img/logo.jpg" width="100" height="100"/>-->

<!--    <div class="page_top">-->
<!--        <h1 class="page_name">proinfotrade.ru</h1>-->
<!--        <div class="page_current_info" id="page_current_info"><span class="current_text">Онлайн открытые позиции по производным финансовым инструментам Мосбиржи</span>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<div class="text-center bg-transparent">
    <h2><span [hidden]="!is">Online информация</span><span [hidden]="is">Информация</span> об открытых позициях по фьючерсным контрактам</h2>
</div>

<div class="row flex-row">
    <div style="float: right;" [hidden]="!is"><em class="note">Обновление каждые 5 минут</em></div>
</div>

<div class="total-table">
    <ng-container *ngFor="let total_row of tlist">
        <div class="row">
            <ng-container *ngFor="let total of total_row">
                <table class="table1" *ngIf="total.table[0]">
                    <tbody>
                    <tr>
                        <th rowspan="2" class="td-info">
                            <div class="td-text-uptime">{{total.table[0].datetime | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
                            <div class="td-text-ticker">{{total.tickerCode}} фьючерс</div>
                            <div>{{selectedTickerName(total.tickerCode)}}</div>
                        </th>
                        <th colspan="2">Физические лица</th>
                        <th colspan="2">Юридические лица</th>
                        <th rowspan="2" class="td-summ">Сумма</th>
                    </tr>
                    <tr>
                        <th class="td-longshort">Лонг</th>
                        <th class="td-longshort">Шорт</th>
                        <th class="td-longshort td-green">Лонг</th>
                        <th class="td-longshort td-red">Шорт</th>
                    </tr>
                    <tr *ngFor="let row of total.table; let even = even">
                        <td>{{row?.name}}</td>
                        <td [class]="{'font-green': row.fpos_long>0 && !even, 'font-red': row.fpos_long<0 && !even}">{{row.fpos_long | numberWithSign: !even}}</td>
                        <td [class]="{'font-green': row.fpos_short>0 && !even, 'font-red': row.fpos_short<0 && !even}">{{row.fpos_short | numberWithSign: !even}}</td>
                        <td class="td-green"
                            [class]="{'font-green': row.ypos_long>0 && !even, 'font-red': row.ypos_long<0 && !even}">{{row.ypos_long | numberWithSign: !even}}</td>
                        <td class="td-red"
                            [class]="{'font-green': row.ypos_short>0 && !even, 'font-red': row.ypos_short<0 && !even}">{{row.ypos_short | numberWithSign: !even}}</td>
                        <td [class]="{'font-green': row.spos>0 && !even, 'font-red': row.spos<0 && !even}">{{row.spos | numberWithSign: !even}}</td>
                    </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </ng-container>


</div>



