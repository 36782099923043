import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {EChartsOption} from "echarts";
import {UtilService} from "../../../services/util.service";

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {

  @Input() data: any;
  @Input() legendTitle: any;
  @Input() yTitle: any;
  @Input() bgColor: any;
  chartOption: EChartsOption = {};

  ngOnChanges(): void {
    let table = this.data;
    this.chartOption = {
      title: {
        text: this.legendTitle,
        textStyle: {
          fontSize: 20,
          color: '#000000',
          opacity: 0.2
        },
        z: 0,
        left: '50%',
        top: '10%',
        textAlign: 'center',
        textVerticalAlign: 'middle',
        subtext: this.yTitle,
        subtextStyle: {
          fontSize: 20,
          color: '#000000',
          opacity: 0.2
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        trigger: 'axis',
        // valueFormatter: (value) => value.toLocaleString(),
        formatter: function (params: any, ticket) {
          if (params.length > 0) {
            let d = UtilService.formatToSringWithTime(UtilService.formatFromSring(params[0].axisValueLabel.replace(' ', 'T')));
            for (let i = 0; i < 1; i++) {
              let idx = params[i].dataIndex;
              let value = table[i].data[idx][1];
              let oldValue = table[i].data[idx][1];
              if (idx > 0)
                oldValue = table[i].data[idx - 1][1]
              let diff = value - oldValue;
              let diffStr = '';
              d += '<div style="display: flex; justify-content: space-between;">' +
                '<div style="margin-right: 10px; background-color:' + params[i].color + ';-webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px; width: 10px;height: 10px;margin-top:5px"> </div>' +
                '<span style="padding-right: 10px">' + params[i].value[1].toLocaleString() + ' <span>' + diffStr + '</span></span>' +
                '</div>';
            }
            return d;
          }
          return 'Loading';
        }
      },
      toolbox: {
        show: false,
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        //name: 'Время',
        type: 'time',
        splitLine: {
          show: true
        }
      },
      yAxis: {
        axisLabel: {
          formatter: function _(val: any) {
            return val.toLocaleString();
          }
        },
        //name: this.yTitle,
        scale: true
      },
      grid: {
        show: true,
        backgroundColor: this.bgColor,
        left: 70,
        right: 20,
        bottom: 70,
        top: 20,
        containLabel: false
      },
      series: this.data,
      visualMap: {
        showLabel: false,
        show: false,
        pieces:
          [
            {
              gte: 0,
              color: '#00da3c'
            },
            {
              lt: 0,
              color: '#ec0000'
            }
          ]
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
        },
        {
          type: 'slider',
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: false,
          xAxisIndex: [0, 1],
          realtime: false,
          start: 0,
          end: 100,
          height: 20,
          handleIcon:
            'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '120%'
        }
      ]
    };
  }

}
