// line-chart.component.ts
import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {EChartsOption} from 'echarts';
import {formatNumber} from "@angular/common";
import {UtilService} from "../../../services/util.service";
import {style} from '@angular/animations';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnChanges {

    @Input() data: any;
    @Input() legendTitle: any;
    @Input() legendSubTitle: any;
    @Input() legend: any;
    @Input() zoom: any;
    @Input() format: any;
    chartOption: EChartsOption = {};
    @Input() height: any;
    @Input() width: any;

    ngOnChanges(): void {
        if (!this.height)
            this.height = 400
        let min = 0
        let max = 0
        for (let d in this.data) {
            let sortedData = Object.assign([], this.data[d].data).sort(function (a: any, b: any) {
                if (a[1] > b[1]) return 1;
                if (a[1] < b[1]) return -1;
                return 0;
            });
            if (sortedData.length > 0 && sortedData[0].length > 1 && (min > sortedData[0][1] || min == 0)) min = sortedData[0][1]
            if (sortedData.length > 0 && sortedData[sortedData.length - 1].length > 1 && (max < sortedData[sortedData.length - 1][1] || min == 0)) max = sortedData[sortedData.length - 1][1]
        }
        const rangeMax = max.toString().length - 1;
        const rangeMin = min.toString().length - 1;

        min = Math.floor(min / Math.pow(10, rangeMax)) * Math.pow(10, rangeMax);
        max = Math.ceil(max / Math.pow(10, rangeMax)) * Math.pow(10, rangeMax);

        let type = "date";
        let title = "Дата";
        if (this.format == "time") {
            type = "time";
            title = "Время";
        }
        let table = this.data;

        this.chartOption = {
            tooltip: {
                trigger: 'axis',
                valueFormatter: (value) => {
                    return value ? value.toLocaleString() : '';
                }
            },
            toolbox: {
                show: false,

                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                axisLabel: {
                    formatter: function _(val: any) {
                        let d = new Date(val - 86400 * 1000);
                        return UtilService.formatTo(d, type);
                    }
                },
                axisLine: {
                    show: true,
                    onZero: true,
                    // onZeroAxisIndex: this.data[0][0] //не понял зачем это нужно
                },
                axisTick: {
                    show: true,

                },
                nameLocation: "start",
                //name: title,
                type: 'time',

                splitLine: {
                    show: true
                }
            },
            yAxis: {
                axisLabel: {
                    formatter: function _(val: any) {
                        return val.toLocaleString();
                    }
                },
                position: "right",
                //name: 'Позиции',
                nameGap: 20,
                type: 'value',
                min: min,
                max: max,
                nameLocation: 'start'
            },
            series: this.data
        };
        if (this.legendTitle) {
            this.chartOption.title = {
                text: this.legendTitle,
                textStyle: {
                    fontSize: 20,
                    color: '#000000',
                    opacity: 0.2
                },
                z: 0,
                left: '50%',
                bottom: '50%',
                textAlign: 'center',
                textVerticalAlign: 'middle',
                subtext: this.legendSubTitle,
                subtextStyle: {
                    fontSize: 20,
                    color: '#000000',
                    opacity: 0.2
                }
            }
            //this.chartOption.legend = {}
        }
        if (this.legend) {
            this.chartOption.legend = {}
        }
        // if (type == "date") {
        this.chartOption.tooltip = {
            trigger: 'axis',
            formatter: function (params: any, ticket) {
                if (params.length == 2) {
                    let d = UtilService.formatToSringWithTime(UtilService.formatFromSring(params[0].axisValueLabel.replace(' ', 'T')));
                    for (let i = 0; i < params.length; i++) {
                        let idx = params[i].dataIndex;
                        let value = table[i].data[idx][1];
                        let oldValue = table[i].data[idx][1];
                        if (idx > 0)
                            oldValue = table[i].data[idx - 1][1]
                        let diff = value - oldValue;
                        let diffStr = '';
                        if (type == "date" && diff > 0)
                            diffStr = "(<span style='color: green'>+" + diff.toLocaleString() + "</span>)"
                        if (type == "date" && diff < 0)
                            diffStr = "(<span style='color: red'>" + diff.toLocaleString() + "</span>)"
                        d += '<div style="display: flex; justify-content: left;">' +
                            '<div style="margin-right: 10px; background-color:' + params[i].color + ';-webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px; width: 10px;height: 10px;margin-top:5px"> </div>' +
                            '<span style="padding-right: 10px">' + params[i].seriesName + '</span>' +
                            '<span style="padding-right: 10px">' + params[i].value[1].toLocaleString() + ' <span>' + diffStr + '</span></span>' +
                            '</div>';
                    }
                    return d;
                }
                return 'Loading';
                // }
            }
        }
        if (this.zoom) {
            this.chartOption.dataZoom = [
                {
                    type: 'slider',
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: false,
                    xAxisIndex: [0, 1],
                    realtime: false,
                    start: 0,
                    end: 100,
                    height: 20,
                    handleIcon:
                        'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '120%'
                }
            ];
            this.chartOption.grid = {
                left: 20,
                right: 20,
                bottom: 50,
                top: 20,
                containLabel: true,
            }
        } else {
            this.chartOption.grid = {
                left: 20,
                right: 70,
                bottom: 20,
                top: 20,
                containLabel: false
            }
        }
    }

}
