export const environment = {
  production: true,
  password: '0dkv6xhisk4g8xgz',
  terminal: '1699928488358DEMO',
  baseUrl: 'https://dev-api.betatrader.ru',
  telegram_bot: 'Betatrader1Bot',
// baseWs: 'wss://dev-api.betatrader.ru'
  // baseUrl: 'https://dev-api-csh.betatrader.ru',
  baseWs: ''
};
